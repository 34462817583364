import { Link } from 'rebass';
import React from 'react';

import Layout from '../components/layout';
import PageHeading from '../components/page-heading';
import SEO from '../components/seo';

const FeeSchedulePage = () => (
  <Layout>
    <SEO title="Fee Schedule" />
    <PageHeading>Fee Schedule</PageHeading>
    <p>
      <Link href="mailto:ian@andersonarbitration.ca">Click here</Link>
      {' '}
to request Ian&rsquo;s current fee schedule.
    </p>
  </Layout>
);

export default FeeSchedulePage;
